@use '@/styles/utils/mixins.scss' as *;

.contactFormWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;

  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  .contactFormField {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.5rem;
    row-gap: 2rem;
    max-width: 52.5rem;
    @include tab() {
      grid-template-columns: repeat(1, 1fr);
    }

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }
      .float-label {
        .as-label,
        .ant-select-selector,
        .float-input {
          background: $white !important;
        }
      }
    }
  }

  .acceptTerms {
    max-width: 22rem;
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
